

















































































import { downloadFile } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import {
  DATE_FORMAT_REPORT,
  DEFAULT_DATE_FORMAT
} from "@/models/constant/date.constant";
import { ReportStockOpname } from "@/models/interface-v2/report.interface";
import { reportService } from "@service/report.service";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import Vue from "vue";
export default Vue.extend({
  name: "ReportStockOpname",
  components: {
    CSelectBatchNumber: () =>
      import(
        /*webpackPrefetch: true*/ "@/components/shared/select-batch-number/CSelectBatchNumber.vue"
      ),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        branchId: undefined as string | undefined,
        productId: undefined as string | undefined,
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        batchId: undefined as string | undefined,
        rackId: undefined as string | undefined,
        asOfDate: moment() as Moment | null,
      },
      rules: {
        branchId: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        productCode: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        productName: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        rackId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        batchId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    onSelectProduct(meta, source: "code" | "name"): void {
      this.form.productId = meta.meta?.id || null;
      if (source === "code") {
        this.form.productName = meta.meta?.name || undefined;
      } else {
        this.form.productCode = meta.meta?.code || undefined;
      }
    },
    validateForm(): void {
      const form = this.$refs.form as FormModel;
      form.validate((valid: boolean) => {
        if (valid) {
          this.handleDownload();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading = true;
        const payload: ReportStockOpname = {
          batchId: this.form.batchId,
          locationId: this.form.rackId,
          date: this.form.asOfDate?.format(DATE_FORMAT_REPORT),
          productId: this.form.productId,
          branchId: this.form.branchId || "",
        };
        const file = await reportService.stockOpname(payload);
        downloadFile(
          new Blob([file]),
          `report_stock_opname_${payload.date}.xlsx`
        );
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading = false;
      }
    },
    handleClear(): void {
      const form = this.$refs.form as FormModel;
      form.resetFields();
      this.form.productId = undefined;
    },
  },
});
